function load()
{
    setTimeout(function()
    {
       document.querySelector(".hero").classList.add("loaded");
    },250);

    if(!localStorage["mayma_cookies"])
    {
        document.getElementById("cookies").classList.remove("hide");    
        document.getElementById("btn_cookies_close").addEventListener('click', 
        function(e)
        {
            e.preventDefault();
            document.getElementById("cookies").classList.add("hide");
            localStorage["mayma_cookies"]=true;
        },false);
    }

    document.getElementById("btn_contact").addEventListener("click",async e=>{
        e.preventDefault();
       
        if (!document.getElementById('contact-form').checkValidity()) 
            return;
        
        try
        {
            const res = await fetch((location.hostname === "localhost" 
                            ? "http://localhost:8080/"
                            : "https://ardilla.email/")+"mayma_contact",
                { 
                    method: 'post',
                    mode: "cors",
                    headers:{'Accept': 'application/json','Content-Type': 'application/json'},
                    body:JSON.stringify({
                        contact_name:document.getElementById("contact_name").value,
                        contact_email:document.getElementById("contact_email").value,
                        contact_message:document.getElementById("contact_message").value
                    })
                });	

            const json=await res.json();
            if(!json) throw new Error("server_error");
            if(!json.status) throw new Error("server_error");

            document.getElementById("contact_ok").classList.remove("hide");
            document.getElementById("contact_ko").classList.add("hide");
            e.target.classList.add("hide");
        }catch(e){
           document.getElementById("contact_ko").classList.remove("hide");
        }
        
    });
}

document.addEventListener("DOMContentLoaded", load, false);